import { BreakpointsEnum } from './_breakpointsHelper'

export type CardT = {
  text: string;
  cta?: string;
  imgSrc: {
    [key in BreakpointsEnum]: string;
  };
  imgAlt: string;
  link?: string;
}

export const CONTEUDO_CARDS: CardT[] = [
  {
    text: 'Maquininha com taxas personalizadas, também no seu celular.',
    cta: 'Saiba mais',
    imgSrc:
    {
      [BreakpointsEnum.sm]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/360-interpay-mei/image.webp',
      [BreakpointsEnum.md]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/mei-dobra2-card1-768/image.webp',
      [BreakpointsEnum.lg]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/mei-dobra2-card2-1024/image.webp',
      [BreakpointsEnum.xl]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/mei-dobra2-card1-1140/image.webp',
    },
    imgAlt: 'Maquininha de cartão ao lado de um smartphone com o Super App do Inter em destaque.',
    link: 'https://inter.co/empresas/maquininha-de-cartao/',
  },
  {
    text: 'Investimentos para rentabilizar o caixa do seu negócio.',
    cta: 'Saiba mais',
    imgSrc: {
      [BreakpointsEnum.sm]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/360-investimentos-mei/image.webp',
      [BreakpointsEnum.md]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/mei-dobra2-card2-768/image.webp',
      [BreakpointsEnum.lg]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/mei-dobra2-card1-1024/image.webp',
      [BreakpointsEnum.xl]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/mei-dobra2-card2-1140/image.webp',
    },
    imgAlt: ' Conta digital MEI Inter Empresas.',
    link: 'https://inter.co/empresas/investimento-empresarial/',
  },
  {
    text: 'Pix gratuito e ilimitado.',
    imgSrc: {
      [BreakpointsEnum.sm]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/360-produto3-mei/image.webp',
      [BreakpointsEnum.md]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/768-produto3-mei/image.webp',
      [BreakpointsEnum.lg]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/768-produto3-mei/image.webp',
      [BreakpointsEnum.xl]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/1440-produto3/image.webp',
    },
    imgAlt: 'Ilustração de um comprovante de pix na conta MEI do Inter.',
  },
  {
    text: 'Emissão gratuita de boletos mensalmente.',
    cta: 'Saiba mais',
    imgSrc: {
      [BreakpointsEnum.sm]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/360-produto4-mei/image.webp',
      [BreakpointsEnum.md]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/768-produto4-mei/image.webp',
      [BreakpointsEnum.lg]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/768-produto4-mei/image.webp',
      [BreakpointsEnum.xl]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/1440-produto4/image.webp',
    },
    imgAlt: 'Ilustração de um boleto de cobrança Inter Empresas',
    link: 'https://inter.co/empresas/gestao-de-cobranca/',
  },
  {
    text: 'Shopping exclusivo com Gift Cards, área para viagens e mais.',
    cta: 'Saiba mais',
    imgSrc: {
      [BreakpointsEnum.sm]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/360-produto5-mei/image.webp',
      [BreakpointsEnum.md]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/768-produto5/image.webp      ',
      [BreakpointsEnum.lg]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/768-produto5-mei/image.webp',
      [BreakpointsEnum.xl]: 'https://central-imagens.bancointer.com.br/images-without-small-versions/1440-produto5/image.webp',
    },
    imgAlt: 'Smartphone ao lado de um notebook.',
  },
]
