import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const CardTextContainer = styled.div`
  position: absolute;
  padding: 30px;
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${breakpoints.sm}) {
    h3, a {
      font-size: 14px;
      line-height: 15px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-left: 60px;
    padding-right: 15px;
  }
`

export const CardTextContainerTop = styled(CardTextContainer)`
  padding: 30px;
  @media (min-width: ${breakpoints.lg}) {
    padding: 40px;
  }
  display: block;
  height: auto;
  max-width: unset;
  width: 100%;
`
