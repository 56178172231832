import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const ImgSize = styled.div`
  .gatsby-image-wrapper {

    @media ${device.tablet} {
      width: 328px;
      float: right;
    }

    @media ${device.desktopLG} {
      width: 456px;
      float: right;
    }

    @media ${device.desktopXL} {
      width: 551px;
      float: right;
    }
  }
`

export const BreadcrumbContainer = styled.div`
  div[role=navigation] {
    margin: 0;

    div:first-of-type span {
      color: ${grayscale[400]} !important;
    }
  }

  svg {
    margin: 4px;
`
