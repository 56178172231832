import React from 'react'
import useUtms from 'src/hooks/useUtms'

import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/utils/breakpoints'

import { ImgSize } from './style'
import SectionComponent from 'src/components/SectionComponent'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { Heading2, Paragraph } from '../../components/Text/_index'
import { ButtonLink } from '../../components/Button/_index'

type CartaoDeCreditoMEIProps = {
  sendDatalayerEvent: Function;
}

const CONTEUDO_TEXTO = {
  h2_destaque: 'Cartão de crédito para MEI.',
  h2_regular: ' Você usa. A gente te recompensa.',
  cta_conta: 'Abrir conta MEI gratuita',
  cta_loop: 'Saiba mais sobre o Inter Loop',
  paragrafo: 'Gratuito e com programa de pontos, o Inter Loop, você escolhe como resgatar:  cashback, desconto na fatura ou compras no Shopping do Inter Empresas.',
}

const CONTEUDO_DATA_LAYER = {
  section: 'dobra_03',
  element_action: 'click button',
  section_name: 'Cartão de crédito para MEI. Você usa. A gente te recompensa.',
}

const CartaoDeCreditoMEI = ({ sendDatalayerEvent }: CartaoDeCreditoMEIProps) => {
  const windowWidth = useWidth(100)

  const openAccountMEIUrl = useUtms({ link: `${process.env.OPEN_ACCOUNT_MEI}`, campanha: '' })
  const interLoopUrl = useUtms({ link: `${process.env.SITE_BASE_URL}/empresas/programa-de-pontos-para-empresas/`, campanha: '' })

  return (
    <SectionComponent
      SectionStyles='py-5'
      RowStyles='mw-100 mx-auto'
      id='cartao-de-credito-mei'
      minHeight={{ sm: '677px', md: '431px', lg: '479px', xl: '677px' }}
    >
      <div className='row align-items-md-center'>
        <ImgSize className='col-12 col-md-6 col-xl-5 mb-4 pl-0'>
          <ImageWebpWithSizes pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/conta-digital-mei-dobra-3/image.webp' altDescription='Cartão de crédito Inter Empresas.' />
        </ImgSize>
        <div className='col-12 col-md-6 mt-2 mb-4'>
          <Heading2 className='mb-4'><strong>{CONTEUDO_TEXTO.h2_destaque}</strong>{CONTEUDO_TEXTO.h2_regular}</Heading2>
          <Paragraph className='mb-4'>{CONTEUDO_TEXTO.paragrafo}</Paragraph>
          {windowWidth >= WIDTH_MD &&
            <ButtonLink
              outline
              target='_blank'
              rel='noreferrer'
              href={interLoopUrl}
              className='mb-md-2'
              onClick={() => {
                  sendDatalayerEvent({
                    ...CONTEUDO_DATA_LAYER,
                  element_name: CONTEUDO_TEXTO.cta_loop,
                  redirect_url: openAccountMEIUrl,
                  })
              }}
            >
              {CONTEUDO_TEXTO.cta_loop}
            </ButtonLink>
            }
          <ButtonLink
            className='mt-2'
            target='_blank'
            rel='noreferrer'
            href={openAccountMEIUrl}
            data-home-mei='hero'
            onClick={() => {
                sendDatalayerEvent({
                  ...CONTEUDO_DATA_LAYER,
                  element_name: CONTEUDO_TEXTO.cta_conta,
                  redirect_url: openAccountMEIUrl,
                })
            }}
          >
            {CONTEUDO_TEXTO.cta_conta}
          </ButtonLink>
        </div>
      </div>
    </SectionComponent>
  )
}

export default CartaoDeCreditoMEI
