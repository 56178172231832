export const BreakpointsEnum = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export const getBreakpoint = (value: number) => {
  const entries = Object.entries(BreakpointsEnum)
  let size = BreakpointsEnum.md

  for (let i = 0; i < entries.length; i++) {
    const [ breakpoint, breakpointValue ] = entries[i]

    if (value >= breakpointValue) {
      size = breakpointValue
    }
  }

  return size
}
