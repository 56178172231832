import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Motivos = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    height: 96px;
  }
`
