import React, { ReactNode } from 'react'
import ScrollTo from 'src/components/ScrollTo'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'
import Icon from 'src/components/UI/MarkdownIcon'
import { breakpoints } from 'src/styles/breakpoints'

export const ButtonLink = styled.a<{outline?: boolean; width?: string}>`
  width: ${props => props.width ?? 'inherit'};;
  height: 40px;
  background-color: ${props => props.outline ? 'transparent' : orange.extra};
  border: ${props => props.outline ? `1px solid ${orange.extra}` : 'none'};
  color: ${props => props.outline ? orange.extra : '#fff' };
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.outline ? '#FFF2E7' : '#FF9D42 '};
    color: ${props => props.outline ? orange.extra : '#fff '};
  }
`

type ScrollToButtonT = { targetId: string; section: string; sectionName: string; elementName: string; children: ReactNode }

const TextContainer = styled.p`
  color: ${orange.extra};
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0;
  @media (min-widht: ${breakpoints.lg}) {
    font-size: 16px;
  }

  @media (min-widht: ${breakpoints.xl}) {
    font-size: 18px;
  }
`

const ScrollLinkContainer = styled.div`
  max-width: 200px;
  margin: 0 auto;
  .scroll-link {
    fill: ${orange.extra};
    display: inline-block;
  }
`
const ScrollToButton = ({ targetId, section, sectionName, elementName, children }: ScrollToButtonT) => {
  return (
    <ScrollLinkContainer>
      <ScrollTo
        to={targetId}
        styles='scroll-link text-center'
        section={section}
        sectionName={sectionName}
        elementName={elementName}
      >
        <TextContainer>{children}</TextContainer>
        <Icon color='orange' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
      </ScrollTo>
    </ScrollLinkContainer>
  )
}

export default ScrollToButton
