import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Wrapper = styled.div`

  h1, h2, h3, h4, h5, .tag-section {
    font-family: 'Citrina', sans-serif;
  }
`
export const ContentHeader = styled.section`
  align-items: center;
  display: flex;
  min-height: 344px;

  @media ${device.tablet} {
    min-height: 500px;
  }
  @media ${device.desktopLG} {
    min-height: 612px;
  }
  @media ${device.desktopXL} {
    min-height: calc(100vh - 73px);
  }

  button {
    @media ${device.tablet} {
     max-width: 456px;
    }
  }

  .scroll-link {
    bottom: 20px;
    fill: ${orange.medium};
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
`
