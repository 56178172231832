import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

const Heading1 = styled.h1`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: ${grayscale[500]};
  margin: 0;
  
  @media (min-width: ${breakpoints.lg}) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (min-width: ${breakpoints.xl}) {
    font-size: 48px;
    line-height: 60px;
  }
`

export const Heading2 = styled(Heading1).attrs({
  as: 'h2',
})`
  font-size: 24px;
  line-height: 30px;
  @media (min-width: ${breakpoints.lg}) {
    font-size: 32px;
    line-height: 40px;
  }
`

export const Heading3 = styled(Heading1).attrs({
  as: 'h3',
})`
  font-size: 16px;
  line-height: 20px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 18px;
    line-height: 21px;
  }

  @media (min-width: ${breakpoints.xl}) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const AccentText = styled.span`
  font-weight: 600;
  font-size: inherit;
  line-height: inherit;
  color: ${orange.extra};
`

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${grayscale[500]};
`
export default Heading1
