
import React, { useEffect } from 'react'

import useWidth from 'src/hooks/window/useWidth'

import SectionComponent from 'src/components/SectionComponent'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { AccentText, Heading2, Heading3 } from '../../components/Text/_index'
import { Link } from '@interco/inter-ui/components/Link'
import { initTheme, Theme } from '@interco/inter-ui'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { CardT, CONTEUDO_CARDS } from './_data'
import { BreakpointsEnum, getBreakpoint } from './_breakpointsHelper'
import { CardTextContainer, CardTextContainerTop } from './style'

const CONTEUDO_TEXTO = {
  h2_regular: 'Por que abrir uma',
  h2_destaque: ' Conta MEI Inter Empresas?',
}

const PorqueAbrirContaMEI = () => {
  const width = useWidth()

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <SectionComponent
      SectionStyles='py-5 bg-grayscale--100'
      RowStyles='mw-100 mx-auto'
      id='porque-abrir-conta-mei'
      minHeight={{ sm: '995px', md: '634px', lg: '815px', xl: '1028px' }}
    >
      <div className='col mb-3 p-0'>
        <Heading2>{CONTEUDO_TEXTO.h2_regular}<AccentText>{CONTEUDO_TEXTO.h2_destaque}</AccentText></Heading2>
      </div>
      <div className='row'>
        {width < WIDTH_MD && (
          <PorqueAbrirContaMEI.OneColumnView />
        )}
        {width >= WIDTH_MD && (
          <PorqueAbrirContaMEI.MultipleColumnsView width={width} />
        )}
      </div>
    </SectionComponent>
  )
}

PorqueAbrirContaMEI.OneColumnView = () => (
  <>
    {CONTEUDO_CARDS.map((card: CardT, index: BreakpointsEnum) => (
      <div className='col-12 mb-2' key={`card_dobra_02_${index}`}>
        <CardTextContainer style={{ [index % 2 === 0 ? 'left' : 'right']: 0 }}>
          <Heading3>{card.text}</Heading3>
          {card.link && (
            <Link
              href={card.link}
              color='primary'
              variant='body-1'
              colorWeight={500}
              bold
              className='mt-2'
            >
              {card.cta}
            </Link>
            )}
        </CardTextContainer>
        <ImageWebpWithSizes pathSrc={card.imgSrc[BreakpointsEnum.sm]} altDescription={card.imgAlt} />
      </div>
    ))}
  </>
)

PorqueAbrirContaMEI.MultipleColumnsView = ({ width }: { width: number }) => {
  return (
    <>
      <div className='row'>
        {CONTEUDO_CARDS.slice(0, 2).map((card: CardT, index: number) => {
          const imgSrc = card.imgSrc[getBreakpoint(width)]
          return (
            <div className='col-md-6 px-md-2 mb-2' key={`card_dobra_02_${index}`}>
              <div>
                <CardTextContainer style={{ right: 0 }}>
                  <Heading3>{card.text}</Heading3>
                  {card.link && (
                    <Link
                      href={card.link}
                      color='primary'
                      variant='body-1'
                      colorWeight={500}
                      bold
                      className='mt-2'
                    >
                      {card.cta}
                    </Link>
                  )}
                </CardTextContainer>
                <ImageWebpWithSizes pathSrc={imgSrc} altDescription={card.imgAlt} />
              </div>
            </div>
          )
        })}
      </div>
      <div className='row'>
        {CONTEUDO_CARDS.slice(2, 5).map((card: CardT, index: number) => {
          const imgSrc = card.imgSrc[getBreakpoint(width)]
          return (
            <div className='col-md-4 px-md-2 mb-2' key={`card_dobra_02_${index}`}>
              <div>
                <CardTextContainerTop>
                  <Heading3>{card.text}</Heading3>
                  {card.link && (
                    <Link
                      href={card.link}
                      color='primary'
                      variant='body-1'
                      colorWeight={500}
                      bold
                      className='mt-2'
                    >
                      {card.cta}
                    </Link>
                  )}
                </CardTextContainerTop>
                <ImageWebpWithSizes pathSrc={imgSrc} altDescription={card.imgAlt} />
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default PorqueAbrirContaMEI
