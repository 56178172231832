import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    width: 290px;

    @media ${device.tablet} {
      width: 360px!important;
    }

    @media ${device.desktopXL} {
      width: 456px!important;
    }
  }
`
