import React from 'react'
import useUtms from 'src/hooks/useUtms'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Motivos } from './style'
import { Heading2, Paragraph } from '../../components/Text/_index'
import SectionComponent from 'src/components/SectionComponent'
import { ButtonLink } from '../../components/Button/_index'
import { orange } from 'src/styles/colors'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

type MotivosInterEmpresasProps = {
  sendDatalayerEvent: Function;
}

const CONTEUDO_TEXTO = {
  h2: 'Motivos para escolher a conta jurídica Inter Empresas',
  paragrafo: 'Uma conta 100% digital, gratuita e completa para simplificar a gestão do seu negócio.',
  cta: 'Abrir conta MEI gratuita',
}

const CONTEUDO_DATA_LAYER = {
  section: 'dobra_04',
  element_action: 'click button',
  section_name: CONTEUDO_TEXTO.h2,
}

type MotivosCardsT = {
  text: string;
  icon: string;
}

const motivosCards: MotivosCardsT[] = [
  {
    text: 'Faça a movimentação da sua conta pessoal e do seu negócio no mesmo lugar',
    icon: 'smartphones',
  },
  {
    text: 'Central de ajuda e chat 24 horas',
    icon: 'user-account',
  },
  {
    text: 'Já simplificamos a vida de mais de 1 milhão de microempreendedores',
    icon: 'market',
  },
  {
    text: 'Sua conta aberta em menos de 5 minutos',
    icon: 'pending',
  },
]

const MotivosInterEmpresas = ({ sendDatalayerEvent }: MotivosInterEmpresasProps) => {
  const openAccountMEIUrl = useUtms({ link: `${process.env.OPEN_ACCOUNT_MEI}`, campanha: '' })
  const width = useWidth()
  return (
    <SectionComponent
      id='motivos-inter-empresas'
      SectionStyles='py-5 bg-orange--extra-light'
      minHeight={{ sm: '365px', md: '542px', lg: '651px', xl: '513px' }}
    >
      <div className='row w-100'>
        <div className='col-12 p-0'>
          <Heading2 className='text-center mb-2'><strong>{CONTEUDO_TEXTO.h2}</strong></Heading2>
          <Paragraph className='text-center mt-2 mb-3'>{CONTEUDO_TEXTO.paragrafo}</Paragraph>
        </div>
      </div>
      <div className='row w-100'>
        {motivosCards.map((motivo: MotivosCardsT, index: number) => (
          <div className='col-12 col-md-6 col-xl-3 p-0' key={index}>
            <Motivos className='mb-2 mb-md-3 pr-3 mr-md-2'>
              <div className='pr-3 pl-md-1'><OrangeIcon size='MD' color={orange.extra} icon={motivo.icon} /></div>
              <div>
                <p className='fs-16 lh-20 text-grayscale--500 fw-400 mb-0'>{motivo.text}</p>
              </div>
            </Motivos>
          </div>
        ))}
        <ButtonLink
          width={width >= WIDTH_MD ? '50%' : '100%'}
          className='mt-2 mx-auto'
          target='_blank'
          rel='noreferrer'
          href={openAccountMEIUrl}
          data-home-mei='hero'
          onClick={() => {
            sendDatalayerEvent({
              ...CONTEUDO_DATA_LAYER,
              element_name: CONTEUDO_TEXTO.cta,
              redirect_url: openAccountMEIUrl,
            })
          }}
        >
          {CONTEUDO_TEXTO.cta}
        </ButtonLink>
      </div>
    </SectionComponent>
  )
}

export default MotivosInterEmpresas
