import React, { useEffect } from 'react'
import useUtms from 'src/hooks/useUtms'

import Heading1, { AccentText, Paragraph } from '../../components/Text/_index'
import ScrollToButton, { ButtonLink } from '../../components/Button/_index'
import { WIDTH_MD, WIDTH_XL } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import { BreadCrumbs } from '@interco/inter-ui/components/BreadCrumbs'
import Home from '@interco/icons/build-v4/orangeds/MD/home'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { BreadcrumbContainer } from './style'
import SectionComponent from 'src/components/SectionComponent'
import { initTheme, Theme } from '@interco/inter-ui'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
}

const CONTEUDO_TEXTO = {
  h1_destaque: 'Conta MEI gratuita:',
  h1_regular: ' uma escolha inteligente',
  paragrafo: 'Com a conta digital do Inter Empresas, você destrava novas possibilidades pro seu negócio.',
  cta: 'Abrir conta MEI gratuita',
  scrollToBtn: 'Conheça a conta MEI',
  altImg: 'Ilustração do cartão de crédito Inter Empresas e de um smartphone com o Super App do Inter em destaque.',
}

const CONTEUDO_DATA_LAYER = {
  section: 'dobra_1',
  element_action: 'click button',
  element_name: CONTEUDO_TEXTO.cta,
  section_name: 'Conta MEI gratuita: uma escolha inteligente',
}

const ContaMEI = ({ sendDatalayerEvent }: IModalDataLayerProps) => {
  const width = useWidth()
  useEffect(() => {
    initTheme(Theme.PF)
  })

  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_MEI}`, campanha: '' })

  return (
    <SectionComponent
      id='hero-conta-mei'
      SectionStyles='py-5'
      minHeight={{ sm: '332px', md: '505px', lg: '545px', xl: '780px' }}
    >
      <div className='row align-items-center text-lg-left mb-md-5'>
        {width >= WIDTH_MD && (
          <div className='col-12 col-md-6 col-lg-6 col-xl-5 offset-xl-1 order-md-last mt-5 mt-md-0 d-none d-md-block'>
            <ImageWebpWithSizes
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/conta-digital-mei-dobra-1/image.webp'
              altDescription={CONTEUDO_TEXTO.altImg}
              widths={{ lg: 486, xl: 562 }}
            />
          </div>
        )}
        <div className='col-12 col-md-6 col-lg-6 col-xl-6 mt-5 mt-md-0'>
          {width >= WIDTH_XL && (
            <div className='row'>
              <BreadcrumbContainer className='col-12 p-0 mb-3'>
                <BreadCrumbs
                  iconFirst={{ icon: <Home height={24} width={24} color='#6A6E81' />, url: 'https://inter.co/' }}
                  configs={{ separator: <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' /> }}
                  params={[
                  { text: 'Empresas' },
                  { text: 'Conta Digital MEI' },
                  ]}
                />
              </BreadcrumbContainer>
            </div>
          )}
          <Heading1 className='mb-3 mt-xl-3'>
            <AccentText>{CONTEUDO_TEXTO.h1_destaque}</AccentText>
            {CONTEUDO_TEXTO.h1_regular}
          </Heading1>
          <Paragraph className='mb-4'>
            {CONTEUDO_TEXTO.paragrafo}
          </Paragraph>
          <div className='d-inline-block w-100 mt-md-2'>
            <ButtonLink
              target='_blank'
              rel='noreferrer'
              href={utmLink}
              onClick={() => {
                sendDatalayerEvent({
                  ...CONTEUDO_DATA_LAYER,
                  redirect_url: utmLink,
                })
              }}
            >
              {CONTEUDO_TEXTO.cta}
            </ButtonLink>
          </div>
        </div>
      </div>
      {width >= WIDTH_MD && (
        <div className='row align-items-center w-100'>
          <div className='col'>
            <ScrollToButton
              targetId='#porque-abrir-conta-mei'
              section='dobra_1'
              sectionName={CONTEUDO_DATA_LAYER.section_name}
              elementName='Conheça a conta MEI'
            >
              {CONTEUDO_TEXTO.scrollToBtn}
            </ScrollToButton>
          </div>
        </div>
      )}
    </SectionComponent>
  )
}

export default ContaMEI
