import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const BlogCarousel = styled.div`
  .primary-tag {
    display: inline;
  }

  &.p-card {
    padding: 0;
  
    @media ${device.tablet} { 
      padding-right: 24px;
    }
    @media ${device.desktopLG} { 
      padding-right: 10.5px;
      padding-left: 10.5px
    }     
  }

  article {
    display: grid;
    min-height: 350px;

    @media ${device.desktopLG} { 
      min-height: 470px;
    }
  }

  .react-multi-carousel-track {
    padding: 30px 0 10px!important;
  }

  .title-post {
    font-family: 'Sora';
    height: 80px;

    @media ${device.tablet} { 
      letter-spacing: 0;
    }
    @media ${device.desktopLG} {
      height: 100px;
      letter-spacing: 1;
    }
    @media ${device.desktopXL} {
      height: 80px;
    }
    @media ${device.desktopXXXL} {
      height: 50px;
    }
  }
`
